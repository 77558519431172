import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { forwardRef, useImperativeHandle } from 'react';
import IconWithText from '@shared/ui/display/IconWIthText';
import Button from '@shared/ui/buttons/Button';
import { AvatarEditorSidebarInner, QuestionIcon, StepContainer, } from './components';
import useAvatarEdit from './useAvatarEdit';
const ConfirmDeleteComponent = ({ setValue, getValues, watch }, outerRef) => {
    const { t } = useTranslation();
    const { declineDelete, confirmDelete, confirmClose, } = useAvatarEdit({ setValue, getValues, watch });
    useImperativeHandle(outerRef, () => ({ handleClose: confirmClose }), []);
    return (_jsx(AvatarEditorSidebarInner, { children: _jsx(StepContainer, { content: (_jsx(IconWithText, { IconElement: _jsx(QuestionIcon, {}), text: t('profile.edit_photo.delete_warning_text', { ns: 'common' }) })), buttons: (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: confirmDelete, children: t('profile.edit_photo.accept_delete_btn', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', fullWidth: true, onClick: declineDelete, children: t('profile.edit_photo.decline_delete_btn', { ns: 'common' }) })] })) }) }));
};
const ConfirmDelete = forwardRef(ConfirmDeleteComponent);
export default ConfirmDelete;
