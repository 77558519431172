import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { forwardRef, useImperativeHandle, useMemo, } from 'react';
import AvatarImage from '@shared/ui/display/AvatarImage';
import FileInput from '@shared/ui/inputs/FileInput';
import { Title } from '@src/components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import useAvatarEdit from './useAvatarEdit';
import { AvatarEditorSidebarInner, StepContainer, AvatarWrapper as AvatarWrapperComponent, ImageIcon, } from './components';
import { getFileInputErrors } from '../../lib';
const AvatarWrapper = styled(AvatarWrapperComponent)(() => ({
    marginBottom: 92,
}));
const InitialComponent = ({ watch, setValue, getValues, }, outerRef) => {
    const { t } = useTranslation();
    const { publicName, imageToDisplay, handleClose, goToNextStep, isConverting, confirmClose, } = useAvatarEdit({ getValues, setValue, watch });
    const handleFileUpload = (filesList) => {
        if (filesList[0]) {
            goToNextStep(filesList[0]);
        }
    };
    useImperativeHandle(outerRef, () => ({ handleClose: confirmClose }), []);
    const fileInputErrors = useMemo(() => getFileInputErrors(t), [t]);
    return (_jsx(AvatarEditorSidebarInner, { displayCancelBtn: true, cancelBtnProps: { onClick: handleClose }, isDisabled: isConverting, children: _jsx(StepContainer, { title: _jsx(Title, { children: t('profile.edit_photo.title', { ns: 'common' }) }), content: (_jsxs(_Fragment, { children: [_jsx(AvatarWrapper, { children: _jsx(AvatarImage, { publicName: publicName, imageUrl: imageToDisplay, size: 'large' }) }), _jsx(FileInput, { onChange: handleFileUpload, innerLabelText: t('profile.edit_photo.file_upload.inner_label', { ns: 'common' }), description: t('profile.edit_photo.file_upload.description', { ns: 'common' }), mimeType: ['image/jpeg', 'image/png'], icon: _jsx(ImageIcon, {}), errors: fileInputErrors, maxSize: 5000000 })] })), buttons: (_jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', fullWidth: true, onClick: handleClose, children: t('profile.edit_photo.cancel_btn', { ns: 'common' }) })) }) }));
};
const Initial = forwardRef(InitialComponent);
export default Initial;
