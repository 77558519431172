import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { forwardRef, useImperativeHandle, useMemo, } from 'react';
import { useMediaQuery } from '@mui/material';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ReactComponent as DeleteIcon } from '@icons/wolfkit-light/trash-light.svg';
import FileInput from '@shared/ui/inputs/FileInput';
import Button from '@shared/ui/buttons/Button';
import { Title } from '@components/styled/Typography';
import { AvatarEditorSidebarInner, AvatarWrapper as AvatarWrapperComponent, ImageIcon, StepContainer, } from './components';
import useAvatarEdit from './useAvatarEdit';
import { getFileInputErrors } from '../../lib';
const AvatarWrapper = styled(AvatarWrapperComponent)(props => ({
    marginBottom: !props.hasDeleteBtn ?
        props.theme.spacing_sizes.s * 15 : props.theme.spacing_sizes.xm,
}));
const ButtonContainer = styled.div(() => ({
    marginBottom: '40px',
}));
const SaveComponent = ({ setValue, getValues, watch, }, outerRef) => {
    const { t } = useTranslation();
    const { isTraderProfile, publicName, imageToDisplay, handleClose, reuploadImage, handleDelete, goToNextStep, confirmClose, } = useAvatarEdit({ setValue, getValues, watch });
    const md = useMediaQuery('(max-height: 800px)');
    const handleFileUpload = (filesList) => {
        if (filesList[0]) {
            reuploadImage(filesList[0]);
        }
    };
    const fileInputErrors = useMemo(() => getFileInputErrors(t), [t]);
    useImperativeHandle(outerRef, () => ({ handleClose: confirmClose }), []);
    return (_jsx(AvatarEditorSidebarInner, { displayCancelBtn: true, cancelBtnProps: { onClick: handleClose }, children: _jsx(StepContainer, { title: _jsx(Title, { children: t('profile.edit_photo.title', { ns: 'common' }) }), content: (_jsxs(_Fragment, { children: [_jsx(AvatarWrapper, { hasDeleteBtn: Boolean(typeof imageToDisplay !== 'undefined' && !isTraderProfile), children: _jsx(AvatarImage, { imageUrl: imageToDisplay, publicName: publicName, size: 'large' }) }), (!isTraderProfile && imageToDisplay) ? (_jsx(ButtonContainer, { children: _jsx(Button, { variant: 'plain', color: 'primary', size: 'medium', startIcon: DeleteIcon, onClick: handleDelete, children: t('profile.edit_photo.delete_photo', { ns: 'common' }) }) })) : null, _jsx(FileInput, { onChange: handleFileUpload, innerLabelText: t('profile.edit_photo.file_upload.inner_label', { ns: 'common' }), description: t('profile.edit_photo.file_upload.description', { ns: 'common' }), mimeType: ['image/jpeg', 'image/png'], errors: fileInputErrors, icon: _jsx(ImageIcon, {}), maxSize: 5000000, size: md ? 'small' : 'medium' })] })), buttons: (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: () => goToNextStep(), children: t('profile.edit_photo.save_btn', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', fullWidth: true, onClick: handleClose, children: t('profile.edit_photo.cancel_btn', { ns: 'common' }) })] })) }) }));
};
const Save = forwardRef(SaveComponent);
export default Save;
