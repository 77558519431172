var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAppSelector } from '@app/store/Hooks';
import { useDeleteAvatarImageMutation, useSendAvatarImageMutation } from '@app/store/slices/file';
import { ImageEditStatus, mapProfileFormToReq, } from '@entities/profile';
import { useGetCountriesListQuery, useGetProfileByIdQuery, useUpdateProfileMutation, } from '@shared/api/profile';
const useProfileEdit = () => {
    const { currentUserProfile, viewMode, profileFormValues, } = useAppSelector((state) => state.profile);
    const [updateProfileReq, { isLoading: isUpdatingProfile, },] = useUpdateProfileMutation();
    const { isFetching, } = useGetProfileByIdQuery({ id: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id }, { skip: typeof (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) === 'undefined' });
    useGetCountriesListQuery({}, { refetchOnMountOrArgChange: false });
    const [deleteAvatarReq, { isLoading: isDeletingAvatar }] = useDeleteAvatarImageMutation();
    const [sendAvatarReq, { isLoading: isSendingAvatar }] = useSendAvatarImageMutation();
    const updateProfileFormData = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const preparedValues = mapProfileFormToReq(values);
        const res = yield updateProfileReq(Object.assign({ id: currentUserProfile.id }, preparedValues));
        if (res === null || res === void 0 ? void 0 : res.data) {
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    });
    const deleteAvatar = () => __awaiter(void 0, void 0, void 0, function* () {
        if (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) {
            const res = yield deleteAvatarReq({ id: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id });
            if ((res === null || res === void 0 ? void 0 : res.data.status) === 204) {
                return true;
            }
            return { error: res === null || res === void 0 ? void 0 : res.error };
        }
        return false;
    });
    const updateAvatar = (image) => __awaiter(void 0, void 0, void 0, function* () {
        if (!(currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id)) {
            return { error: 'critical error, current user profile should be present at this moment.' };
        }
        const res = yield sendAvatarReq({
            profileId: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id,
            image,
        });
        if (res === null || res === void 0 ? void 0 : res.data) {
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    });
    const updateProfile = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id) {
            if (((_a = values.imageEditorState) === null || _a === void 0 ? void 0 : _a.imageEditStatus) === ImageEditStatus.Updated) {
                if (!values.image) {
                    return false;
                }
                return updateAvatar({
                    original: values.image.original,
                    thumbnail: values.image.thumbnail,
                    avatar: values.image.avatar,
                })
                    .then(() => updateProfileFormData(values), (error) => ({ error }));
            }
            if (((_b = values.imageEditorState) === null || _b === void 0 ? void 0 : _b.imageEditStatus) === ImageEditStatus.Deleted) {
                return deleteAvatar().then(() => updateProfileFormData(values), (error) => ({ error }));
            }
            return updateProfileFormData(values);
        }
        return false;
    });
    return {
        updateProfile,
        formInitialValues: profileFormValues,
        viewMode,
        isUpdatingProfile: isUpdatingProfile || isSendingAvatar || isDeletingAvatar,
        isFetching,
    };
};
export default useProfileEdit;
