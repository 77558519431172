import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { forwardRef, useImperativeHandle, useRef, } from 'react';
import ImageEditor from '@shared/ui/misc/ImageEditor';
import { Title } from '@src/components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import useAvatarEdit from './useAvatarEdit';
import { AvatarEditorSidebarInner, StepContainer } from './components';
const EditComponent = ({ setValue, getValues, watch }, outerRef) => {
    const { t } = useTranslation();
    const { tempImage, handleClose, goToNextStep, isConverting, confirmClose, } = useAvatarEdit({ setValue, getValues, watch });
    const editorRef = useRef(null);
    useImperativeHandle(outerRef, () => ({ handleClose: confirmClose }), []);
    const saveImage = () => {
        if (editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) {
            goToNextStep(editorRef.current.getImages() || undefined);
        }
    };
    return (_jsx(AvatarEditorSidebarInner, { displayCancelBtn: true, cancelBtnProps: { onClick: handleClose }, children: _jsx(StepContainer, { title: _jsx(Title, { children: t('profile.edit_photo.title', { ns: 'common' }) }), content: tempImage ? (_jsx(ImageEditor, { image: tempImage, cropImageProps: {
                    rotate: 0,
                    width: 370,
                    height: 370,
                    color: [255, 255, 255, 0.6],
                    borderRadius: 350,
                }, defaultImageZoom: 1.5, minMaxImageZoom: [1, 3], sliderStep: 0.1, ref: editorRef })) : null, buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: saveImage, disabled: isConverting, isLoading: isConverting, children: t('profile.edit_photo.continue_btn', { ns: 'common' }) })) }) }));
};
const Edit = forwardRef(EditComponent);
export default Edit;
